<template>
    <div class="MailAccount">
        <div class="bodyClass">
            <van-cell-group>
                <!-- 电子邮件 必填 -->
                <van-field v-model="username" required clearable :label="$t('mx_mobile.Mail.1585111014224')" :placeholder="$t('mx_mobile.Mail.1585110836075')" />
                <!-- 密码 必填 -->
                <van-field v-model="password" type="password" :label="$t('mx_mobile.Mail.1585111034162')" :placeholder="$t('mx_mobile.Mail.1585110836075')" required />
            </van-cell-group>
            <!-- <span>名称</span>
            <input v-model="username" placeholder="输入文件夹名" />
            <span>名称</span>
            <input v-model="password" placeholder="输入文件夹名" /> -->

        </div>
        <!-- 保存 -->
        <div class="footClass" @click="accountAdd()">{{$t('mx_mobile.Mail.1585111055545')}}</div>
    </div>

</template>
<script>
import titleMixin from '@/mixin/title'
export default {
    name: 'MailAccount',
    title: function fc() { return this.$t('mx_mobile.Mail.1585111080419') }, // '邮箱账号添加'
    mixins: [titleMixin],
    data() {
        return {
            username: '',
            password: '',
            errorMessage: ''
        }
    },
    computed: {

    },
    created() {

    },
    mounted() {
        this.pageInit()
    },
    methods: {
        pageInit() {
            this.Global.utils.rightMenu.clearMenu()
            this.username = ''
            this.password = ''
        },
        async  accountAdd() {
            let _this = this
            this.$toast.loading({ message: '请求中...' })
            let data = {
                'mailAddress': this.username,
                'recPassword': _this.Global.utils.encrypt(this.password)
            }
            var reg = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
            _this.errorMessage = ''
            if (this.username == '' && this.password == '') {
                _this.errorMessage = '账号和密码不允许为空'
            } else if (this.username == '') {
                _this.errorMessage = '账号不允许为空'
            } else if (this.password == '') {
                _this.errorMessage = '密码不允许为空'
            } else if (!reg.test(this.username)) {
                _this.errorMessage = '请输入正确的邮箱帐号'
            } else {
                let mailAddressArry = _this.username.toLowerCase().split('@')
                if (mailAddressArry[1] == 'qq.com') {
                    var regTwo = /^[a-z]{16,16}$/g
                    if (!regTwo.test(_this.password)) {
                        _this.errorMessage = 'QQ邮箱请使用授权码登录'
                    }
                }
            }
            if (_this.errorMessage != '') {
                _this.$toast.clear()
                // this.$dialog.alert({ message: _this.errorMessage })
                this.$toast.fail(_this.errorMessage)
                return
            }
            try {
                let url = this.Global.config.apiBaseURL + this.Global.api.SystemSet.mailset.mailaccount.accountAdd
                let res = await this.axios.post(url, data)
                if (res.data.code.toString() == this.Global.config.RES_OK) {
                    _this.$toast.clear()
                    this.$toast.success('添加账号成功')
                    this.$router.back()
                } else {
                    _this.$toast.clear()
                    this.$toast.fail(res.data.msg)
                }
            } catch (error) {

            }
        }
    },
    watch: {
        $route(to, from) {
            if (this.$route.path == '/mail/MailAccount') {
                this.pageInit()
            }
        }

    }
}
</script>
<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
